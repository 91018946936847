import React from "react";

export interface TagProps extends React.HTMLProps<HTMLDivElement> {
  text: string;
  color: "green" | "blue" | "purple" | "pink" | "orange" | "yellow";
  type: "large" | "small" | "medium";
}
export const Tag = ({
  text,
  type = "large",
  color,
  className,
  ...props
}: TagProps) => {
  return (
    <div
      {...props}
      className={`text-tag text-tag-${type} text-tag-${color} flex items-center justify-center ${className ??
        ""}`}
    >
      {text}
    </div>
  );
};

export default Tag;
