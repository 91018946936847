import React from "react";
import Modal, { Props } from "react-modal";
import { ModalButton } from "../ModalButton";
import { Icon } from "../Icon";

export interface ModalProps extends Props {
  isOpen: boolean;
  closeModal: () => void;
  modalTitle: string;
  onOptionClick: () => void;
  modalSubtitle: string | null;
  buttonLabel: string;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "472px"
  }
};
export const CallToActionModal = ({
  isOpen,
  closeModal,
  modalTitle,
  onOptionClick,
  buttonLabel,
  modalSubtitle = null,
  ...props
}: ModalProps) => {
  return (
    <Modal
      {...props}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="text-2xl font-lato-black text-black">{modalTitle}</div>
      {modalSubtitle ? (
        <div className="text-grey-2 font-lato-bold text-sm mt-2">
          {modalSubtitle}
        </div>
      ) : null}
      <Icon
        onClick={closeModal}
        name="cross"
        size="27"
        className="absolute top-4 right-2 cursor-pointer"
        color="#424452"
      />
      <ModalButton
        label={buttonLabel}
        onClick={onOptionClick}
        type="primary"
        className="mt-9"
      />
      <ModalButton
        label="Cancel"
        onClick={closeModal}
        type="secondary"
        className="mt-4"
      />
    </Modal>
  );
};
