import Quill from "quill";
import "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import "quill-mention";
import React, { useEffect, useMemo, useState } from "react";
import { renderToString } from "react-dom/server";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// import "./style.css";
import {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Attach,
  Bold,
  Emoji,
  Italic,
  Link,
  Mention,
  OrderedList,
  Strikethrough,
  Underline,
  UnorderedList
} from "./toolbarIcons";

const icons = Quill.import("ui/icons");
icons.align = {
  center: renderToString(<AlignCenter />),
  right: renderToString(<AlignRight />),
  "": renderToString(<AlignLeft />)
};
icons.bold = renderToString(<Bold />);
icons.italic = renderToString(<Italic />);
icons.strike = renderToString(<Strikethrough />);
icons.underline = renderToString(<Underline />);
icons.list = {
  ordered: renderToString(<OrderedList />),
  bullet: renderToString(<UnorderedList />)
};
icons.image = renderToString(<Attach />);
icons.link = renderToString(<Link />);
icons.emoji = renderToString(<Emoji />);
icons.mention = renderToString(<Mention />);

const Spacer = () => <div className="section-divider" />;

const CustomToolbar = (props: { identity: string; top: boolean }) => {
  const className = `custom-toolbar custom-toolbar-${
    props.top ? "top" : "bottom"
  }`;
  return (
    <div id={props.identity} className={className}>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
      <Spacer />
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-align" value="" />
      <button className="ql-align" value="center" />
      <button className="ql-align" value="right" />
      <Spacer />
      <button className="ql-image" />
      <button className="ql-link" />
      <Spacer />
      <button className="ql-emoji" />
      <button className="ql-mention" />
    </div>
  );
};
type ListItem = {
  id: number;
  value: string;
};

const getInitials = (name: string) => {
  const firstLetter = name.charAt(0);
  const firstSpaceIdx = name.indexOf(" ");
  if (firstSpaceIdx === -1 || firstSpaceIdx + 1 === name.length)
    return firstLetter.toUpperCase();
  const secondLetter = name.charAt(firstSpaceIdx + 1);
  return (firstLetter + secondLetter).toUpperCase();
};
const hashString = (name: string, radix: number) => {
  let sum = 0;
  for (let i = 0; i < name.length; i++) {
    sum += name.charCodeAt(i);
  }
  if (radix) return sum % radix;
  return sum;
};

const renderItem = (item: ListItem, _searchTerm: string): string => {
  const initials = getInitials(item.value);
  const className = `profile-icon profile-icon-${hashString(item.value, 6)}`;
  return renderToString(
    <div className="mention-suggestion">
      <div className={className}>{initials}</div>
      {item.value}
    </div>
  );
};

// Not an arrow function - must be bound to the toolbar
// also not a callback as it doesn't require instance data
function openMenu(this: { quill: Quill }) {
  const { quill } = this;
  const selection = quill.getSelection(true);
  quill.insertText(selection.index + selection.length, "@");
  quill.blur();
  quill.focus();
}

type Props = {
  initialValue: string;
  placeholder: string;
  onChange?: (newValue: string) => unknown;
  taggables: { value: string; id: unknown }[];
  toolbarBottom?: boolean;
};
export const Editor = ({
  placeholder,
  initialValue,
  onChange,
  taggables,
  toolbarBottom
}: Props) => {
  const top = !toolbarBottom;
  const [value, setValue] = useState(initialValue);
  const TB_ID = "secretIdOfSOmeSort";
  useEffect(() => {
    onChange?.(value);
  }, [value, onChange]);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${TB_ID}`,
        handlers: {
          mention: openMenu
        }
      },
      "emoji-toolbar": {
        buttonIcon: icons.emoji
      },
      mention: {
        allowedChars: /^[A-Za-z]*$/,
        mentionDenotationChars: ["@"],
        renderItem,
        source: (searchTerm: string, renderList: Function) => {
          if (!searchTerm?.length) {
            renderList(taggables, "");
            return;
          }
          const lc = searchTerm.toLowerCase();
          const matches = taggables.filter(
            candidate => candidate.value.toLowerCase().indexOf(lc) >= 0
          );
          renderList(matches, searchTerm);
        }
      }
    }),
    [TB_ID, taggables]
  );
  return (
    <div className="text-editor-container">
      {!!top && <CustomToolbar identity={TB_ID} top />}
      <ReactQuill
        theme="snow"
        value={value}
        onChange={setValue}
        modules={modules}
        placeholder={placeholder}
      />
      {!top && <CustomToolbar identity={TB_ID} top={false} />}
    </div>
  );
};
