import React from "react";

interface Props extends React.HTMLProps<HTMLInputElement> {
  type: "large" | "small";
}
export const RadioButton = ({ type = "large", className, ...props }: Props) => {
  return (
    <input
      type="radio"
      className={`tango-radio-btn tango-radio-btn-${type} ${className ?? ""}`}
      {...props}
    />
  );
};

export default RadioButton;
