import React from "react";
import ReactSwitch, { ReactSwitchProps } from "react-switch";

interface Props extends ReactSwitchProps {
  size: "small" | "medium" | "large";
  showLabel: boolean;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const WrongSwitch = ReactSwitch as any;
const getHeightAndWidthFromSize = (
  size: Props["size"],
  showLabel: Props["showLabel"]
) => {
  switch (size) {
    case "small":
      return {
        width: showLabel ? 47.94 : 37.67,
        height: 24,
        handleDiameter: 20.55
      };
    case "medium":
      return {
        width: showLabel ? 72.75 : 57.75,
        height: 36,
        handleDiameter: 30
      };
    case "large":
    default:
      return { width: showLabel ? 97 : 77, height: 48, handleDiameter: 40 };
  }
};
export const Switch = ({
  size = "large",
  className,
  onChange,
  showLabel = false,
  checked = false,
  ...props
}: Props) => {
  const { width, height, handleDiameter } = getHeightAndWidthFromSize(
    size,
    showLabel
  );
  return (
    <WrongSwitch
      checked={checked}
      onChange={onChange}
      height={height}
      handleDiameter={handleDiameter}
      width={width}
      className={`tango-switch tango-switch-${size} ${className ?? ""}`}
      offColor="#F5F7FB"
      offHandleColor="#DBE2EF"
      onHandleColor="#FFFFFF"
      onColor="#424452"
      uncheckedIcon={showLabel ? <span>off</span> : false}
      checkedIcon={showLabel ? <span>On</span> : false}
      {...props}
    />
  );
};

export default Switch;
