import React from "react";

interface Props extends React.HTMLProps<HTMLTextAreaElement> {
  sizeType: "small" | "large";
}
export const ParagraphField = ({
  sizeType = "large",
  className,
  ...props
}: Props) => {
  return (
    <textarea
      className={`bg-white border rounded-2xl font-lato-regular border-grey-1 outline-0 paragraph-field-${sizeType} ${className ??
        ""}`}
      {...props}
    />
  );
};

export default ParagraphField;
