import React, { ComponentProps } from "react";

type ButtonType = "primary" | "secondary" | "delete" | "white";

type InnerButtonProps = Omit<ComponentProps<"button">, "type">;
interface ButtonProps {
  label: string;
  type: ButtonType;
  onClick?: undefined | (() => void);
  subheading?: string;
  className?: string;
}
type PropType = ButtonProps & InnerButtonProps;
/**
 * These are our modal buttons
 */
const getClassName = (type: ButtonType) => {
  switch (type) {
    case "primary":
      return "modal-btn-primary";
    case "secondary":
      return "modal-btn-secondary";
    case "delete":
      return "modal-btn-delete";
    case "white":
      return "modal-btn-white";
    default:
      return "modal-btn-primary";
  }
};

export const ModalButton = ({
  label,
  type,
  subheading = "",
  className = "",
  onClick = undefined,
  ...innerProps
}: PropType) => {
  const buttonType = getClassName(type);
  return (
    <button
      onClick={onClick}
      type="button"
      className={`modal-btn ${buttonType} ${className ?? ""}`}
      {...innerProps}
    >
      <>{label}</>
      {subheading ? (
        <span className="block text-grey-2 font-lato-bold text-xs mt-3">
          {subheading}
        </span>
      ) : null}
    </button>
  );
};

export default ModalButton;
