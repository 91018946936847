import React from "react";

interface Props extends React.HTMLProps<HTMLInputElement> {
  sizeType: "tiny" | "small" | "medium" | "large";
}
export const TextField = ({
  sizeType = "large",
  className,
  ...props
}: Props) => {
  return (
    <input
      type="text"
      className={`bg-white border border-solid font-lato-regular border-grey-1 rounded-full outline-0 text-field-${sizeType} ${className ??
        ""}`}
      {...props}
    />
  );
};

export default TextField;
