import React from "react";
import DateTimePicker, { DateTimePickerProps } from "react-datetime-picker";

type Props = DateTimePickerProps & {
  size: "large" | "small";
};
export const TimePickerInput = ({
  className,
  size = "large",
  ...props
}: Props) => {
  let extraClass = "";
  if (className) {
    extraClass =
      typeof className === "object" ? className.join(" ") : className;
  }
  return (
    <DateTimePicker
      {...props}
      format="h : mm a"
      disableCalendar
      disableClock
      hourPlaceholder="12"
      minutePlaceholder="00"
      clearIcon={null}
      amPmAriaLabel="Select AM/PM"
      className={`time-picker ${extraClass} time-picker-${size}`}
    />
  );
};

export default TimePickerInput;
