import React, { ComponentProps } from "react";
import { Icon, IconNames } from "../Icon";

type SpanProps = ComponentProps<"span">;
interface NestedButtonProps {
  label: string;
  leftIcon?: IconNames | null;
  rightIcon?: IconNames | null;
  onButtonClick?: undefined | (() => void);
  onRightIconClick?: undefined | (() => void);
  onLeftIconClick?: undefined | (() => void);
}

export const NestedButton = ({
  label,
  rightIcon = null,
  leftIcon = null,
  onButtonClick = undefined,
  onRightIconClick = undefined,
  onLeftIconClick = undefined,
  className,
  ...spanProps
}: NestedButtonProps & SpanProps) => (
  <span
    className={`bg-blue-grey-2 rounded-full flex w-fit items-center px-2 py-1 ${className ??
      ""}`}
    {...spanProps}
  >
    {leftIcon ? (
      <button
        type="button"
        onClick={onLeftIconClick}
        className="p-1 rounded-full bg-transparent hover:bg-blue-grey-4 cursor-pointer border-0"
      >
        <Icon name={leftIcon} size="16" />
      </button>
    ) : null}
    <button
      onClick={onButtonClick}
      type="button"
      className="font-lato-bold text-sm text-black px-[0.9375rem] border-0 py-1 bg-transparent hover:bg-blue-grey-4 rounded-full"
    >
      {label}
    </button>
    {rightIcon ? (
      <button
        type="button"
        onClick={onRightIconClick}
        className="p-1 rounded-full bg-transparent hover:bg-blue-grey-4 cursor-pointer border-0"
      >
        <Icon name={rightIcon} size="16" />
      </button>
    ) : null}
  </span>
);

export default NestedButton;
