import React from "react";
import Modal, { Props } from "react-modal";
import { ModalButton } from "../ModalButton";
import { Icon } from "../Icon";

interface ModalProps extends Props {
  isOpen: boolean;
  closeModal: () => void;
  modalTitle: string;
  onClickOption1: () => void;
  onClickOption2: () => void;
  modalSubtitle?: string;
  optionLabel1: string;
  optionLabel2: string;
}
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "472px"
  }
};
export const OptionModal = ({
  isOpen,
  closeModal,
  modalTitle,
  modalSubtitle = "",
  onClickOption1,
  onClickOption2,
  optionLabel1,
  optionLabel2,
  ...props
}: ModalProps) => {
  return (
    <Modal
      {...props}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <div className="text-2xl font-lato-black text-black">{modalTitle}</div>
      {modalSubtitle ? (
        <div className="text-grey-2 font-lato-bold text-sm mt-2">
          {modalSubtitle}
        </div>
      ) : null}
      <Icon
        onClick={closeModal}
        name="cross"
        size="27"
        className="absolute top-4 right-2 cursor-pointer"
        color="#424452"
      />
      <ModalButton
        label={optionLabel1}
        onClick={onClickOption1}
        type="white"
        className="mt-9"
      />
      <ModalButton
        label={optionLabel2}
        onClick={onClickOption2}
        type="white"
        className="mt-4"
      />
    </Modal>
  );
};

export default OptionModal;
