import React from "react";
import { Icon, IconNames } from "../Icon";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  iconName: IconNames;
  minimal?: boolean;
}
export const IconButton = ({ iconName, minimal = false, ...props }: Props) => {
  return (
    <button
      className={`h-9 w-9 flex justify-center items-center rounded-full border-0 ${
        minimal ? "bg-transparent" : "bg-blue-grey-3"
      }`}
      {...props}
      type="button"
    >
      <Icon name={iconName} size="20" />
    </button>
  );
};

export default IconButton;
