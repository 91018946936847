import React from "react";
import ReactCalendar, { CalendarProps } from "react-calendar";
import { Icon } from "../Icon";

type Props = CalendarProps & {};
export const Calendar = ({ className, ...props }: Props) => {
  return (
    <ReactCalendar
      {...props}
      className={`tango-calendar ${className || ""}`}
      next2Label={null}
      prev2Label={null}
      nextLabel={<Icon name="chevron-right" />}
      prevLabel={<Icon name="chevron-left" />}
      formatShortWeekday={(_, date: Date) =>
        ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
      }
    />
  );
};
