import React, { ReactNode } from "react";
import ReactModal, { Props } from "react-modal";
import { Icon } from "../Icon";

export type CustomModalProps = Props & {
  isOpen: boolean;
  closeModal: () => void;
  children: ReactNode;
  width?: string;
  height?: string;
};

export const Modal = ({
  isOpen,
  closeModal,
  overlayClassName,
  children,
  width,
  height,
  ...props
}: CustomModalProps) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: width + "px" || "500px",
      height: height + "px" || "auto"
    }
  };
  return (
    <ReactModal
      {...props}
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      shouldCloseOnOverlayClick
    >
      <Icon
        onClick={closeModal}
        name="cross"
        size="27"
        className="absolute top-4 right-2 cursor-pointer"
        color="#424452"
      />
      {children}
    </ReactModal>
  );
};
