import React from "react";
import Select, {
  Props as ReactSelectProps,
  components,
  OptionProps
} from "react-select";
import { Icon } from "../Icon";
import { Checkbox } from "../Checkbox";

// interface DropdownProps extends Props {
//     size: 'small' | 'medium' | 'large';
//     showArrow?: boolean;
//     showUserIcon?: boolean;
//     enableCheckbox?: boolean;
// }
interface OptionType {
  label: string;
  value: string;
  color?: string | undefined;
}
export type SelectProps = ReactSelectProps<OptionType> & {
  size: "small" | "medium" | "large";
  showArrow?: boolean;
  showUserIcon?: boolean;
  enableCheckbox?: boolean;
};

export const Dropdown = ({
  size = "large",
  isSearchable,
  showArrow = false,
  showUserIcon = false,
  enableCheckbox = false,
  className,
  ...props
}: SelectProps) => {
  const CustomOption = (props1: OptionProps<OptionType>) => {
    const { data, isSelected } = props1;
    return (
      <components.Option {...props1}>
        <div className="flex justify-between items-center">
          <span className={`flex items-center ${data.color ?? ""}`}>
            {showUserIcon ? (
              <span className="user-icon mr-3">
                {data.label.substring(0, 2)}
              </span>
            ) : null}
            {enableCheckbox ? (
              <Checkbox className="mr-3" type="small" checked={isSelected} />
            ) : null}
            {data.label}
          </span>
          {showArrow ? (
            <Icon name="chevron-right" color="#000" size="16" />
          ) : null}
        </div>
      </components.Option>
    );
  };
  return (
    <Select
      menuPosition="fixed"
      {...props}
      className={`dropdown dropdown-${size} ${
        isSearchable ? "dropdown-searchable" : ""
      } ${className ?? ""}`}
      classNamePrefix="tango"
      isSearchable={isSearchable}
      components={{
        IndicatorSeparator: null,
        Option: CustomOption
      }}
    />
  );
};

export default Dropdown;
