import React, { ComponentProps } from "react";
import { Icon, IconNames } from "../Icon";

export interface ButtonProps {
  label: string;
  type:
    | "btn-style-1"
    | "btn-style-2"
    | "btn-style-outline"
    | "btn-style-minimal";
  size: "btn-tiny" | "btn-small" | "btn-medium" | "btn-large";
  className?: string;
  leftIcon?: IconNames | undefined;
  rightIcon?: IconNames | undefined;
  leftIconColor?: string;
  rightIconColor?: string;
}

type InnerButtonProps = Omit<ComponentProps<"button">, "type">;
/**
 * This our primary button component with style 1, style 2, outline and no border
 */
const getIconSize = (buttonType: ButtonProps["size"]) => {
  switch (buttonType) {
    case "btn-tiny":
    case "btn-small":
      return "12";
    default:
      return "16";
    //TODO: make icon size to be 16 for all case
  }
};
export const Button = ({
  label,
  type,
  size,
  className = "",
  leftIcon = undefined,
  rightIcon = undefined,
  leftIconColor,
  rightIconColor,
  ...innerProps
}: ButtonProps & InnerButtonProps) => (
  <button
    type="button"
    {...innerProps}
    className={`${type} flex items-center ${size} ${className}`}
  >
    {leftIcon ? (
      <Icon
        name={leftIcon}
        className={`${size === "btn-tiny" ? "mr-3" : "mr-4"}`}
        size={getIconSize(size)}
        color={leftIconColor}
      />
    ) : null}
    <>{label}</>
    {rightIcon ? (
      <Icon
        name={rightIcon}
        className="ml-4"
        size={getIconSize(size)}
        color={rightIconColor}
      />
    ) : null}
  </button>
);

export default Button;
