import React, { useState } from "react";
import DateTimePicker, { DateTimePickerProps } from "react-datetime-picker";
import { Icon } from "../Icon";

interface Props extends DateTimePickerProps {
  size: "large" | "small";
}
export const DatePickerInput = ({
  size = "large",
  className,
  ...props
}: Props) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  let extraClass = "";
  if (className) {
    extraClass =
      typeof className === "object" ? className.join(" ") : className;
  }

  return (
    <DateTimePicker
      {...props}
      className={`picker-${size} picker-${
        isCalendarOpen ? "open" : "close"
      } ${extraClass}`}
      dayPlaceholder=" DD "
      monthPlaceholder=" MM "
      yearPlaceholder=" YYYY"
      clearIcon={null}
      format="MM / dd / yyyy"
      calendarIcon={
        <Icon name="chevron-down" size={size === "large" ? "16" : "12"} />
      }
      onCalendarOpen={() => setIsCalendarOpen(true)}
      onCalendarClose={() => setIsCalendarOpen(false)}
      // @ts-ignore
      formatShortWeekday={(_, date: Date) =>
        ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
      }
      next2Label={null}
      prev2Label={null}
      nextLabel={<Icon name="chevron-right" />}
      prevLabel={<Icon name="chevron-left" />}
    />
  );
};

export default DatePickerInput;
