import React from "react";
type colors =
  | "blue"
  | "pink"
  | "orange"
  | "yellow"
  | "purple"
  | "green"
  | "black"
  | "";
interface CardProps extends React.HTMLProps<HTMLInputElement> {
  type: "large" | "small";
  colorType?: colors;
}
export const Checkbox = ({
  type = "large",
  colorType = "",
  className,
  ...props
}: CardProps) => {
  return (
    <input
      type="checkbox"
      className={`tango-checkbox tango-checkbox-${type} ${
        colorType ? `tango-checkbox-${colorType}` : ""
      } ${className ?? ""}`}
      {...props}
    />
  );
};

export default Checkbox;
