import React from "react";
import { Button } from "../Button";

export interface Props extends React.HTMLProps<HTMLDivElement> {
  heading?: string;
  bigHeading?: string;
  subHeading?: string;
  image?: string;
  imageAlt?: string;
  topBannerImage?: string;
  bottomBannerImage?: string;
  buttonLabel?: string;
  onButtonClick?: undefined | (() => void);
  hasShadow?: boolean;
  description?: string;
}
export const Card = ({
  description = "",
  topBannerImage = "",
  bottomBannerImage = "",
  buttonLabel = "",
  onButtonClick = undefined,
  heading = "",
  bigHeading = "",
  subHeading = "",
  image = "",
  imageAlt = "",
  hasShadow = true
}: Props) => {
  return (
    <div
      className={` cursor-pointer w-fit ${
        hasShadow ? "shadow-card" : "border border-grey-1"
      } rounded-2xl ${
        hasShadow ? "hover:shadow-card-hover" : "hover:border-grey-2"
      }`}
    >
      {topBannerImage ? (
        <div
          className="h-20 rounded-t-2xl"
          style={{
            backgroundImage: `url(${topBannerImage})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat"
          }}
        />
      ) : null}
      <div className="pl-8 pt-8 pr-8 pb-11">
        {image ? (
          <img
            alt={imageAlt}
            src={image}
            className="w-full h-64 mb-6 rounded-2xl"
          />
        ) : null}
        {heading || bigHeading ? (
          <div
            className={`text-black font-lato-black ${
              heading ? "text-xl" : "text-3xl"
            }`}
          >
            {heading ?? bigHeading}
          </div>
        ) : null}
        {subHeading ? (
          <div className="font-lato-bold text-sm mt-[0.375rem] text-grey-2">
            {subHeading}
          </div>
        ) : null}
        {description ? (
          <div className="font-lato-regular text-[0.625rem] mt-4 text-black leading-4">
            {description}
          </div>
        ) : null}
        {buttonLabel && onButtonClick ? (
          <Button
            label={buttonLabel}
            type={hasShadow ? "btn-style-1" : "btn-style-outline"}
            size="btn-large"
            className="w-full text-center justify-center mt-8"
            onClick={onButtonClick}
          />
        ) : null}
      </div>
      {bottomBannerImage ? (
        <div
          className="h-20 rounded-b-2xl"
          style={{
            backgroundImage: `url(${bottomBannerImage})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat"
          }}
        />
      ) : null}
    </div>
  );
};

export default Card;
