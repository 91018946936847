import React from "react";

interface Props extends React.HTMLProps<HTMLSpanElement> {
  text: string;
  color: "green" | "blue" | "purple" | "pink" | "orange" | "yellow";
  type: "large" | "medium" | "small";
}
export const NumberTag = ({
  text,
  color = "green",
  type = "small",
  className
}: Props) => {
  return (
    <div
      className={`number-tag number-tag-${color} number-tag-${type} ${className ??
        ""}`}
    >
      {text}
    </div>
  );
};

export default NumberTag;
